body {
  background-color: rgb(37, 37, 37);
  background: linear-gradient(rgb(52, 52, 52) 10%, 30%, rgb(32, 32, 32) 100%)  ;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

