

.poke-logo {
  width: 70%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.centering {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* font-size: 1rem; */
  color: white;
}

.text-it {
  margin-top: 3rem;
  padding: 0 2rem 0 2rem;
  font-size: calc(10px + 1.5vw);
  font-family: 'Montserrat', sans-serif;
  font-weight:400;
  text-align: center;
}

.text-container {
  margin-top: 4rem;
}

.text-en {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 0 2rem 0 2rem;
  font-size: calc(10px + 1.5vw);
  font-family: 'Montserrat', sans-serif;
  font-weight:200;
  text-align: center;
}
.social-logos {
  display: flex;
  justify-content: center;
}


.social-logo {
  margin: 3rem 1.5rem;
  width: 2.5rem;
}



.App-link {
  color: #ffffff;
}

@media (min-width: 600px) {
  .poke-logo {
    width: 420px;
  }
  .text-it {
    font-size: 1.5rem;
  }
  .text-en {
    font-size: 1.4rem;
  }
}

